var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"selection-container"},[(_vm.isShowMit)?_c('v-col',{attrs:{"xs":"12","sm":"12","md":"3","lg":"3","xl":"3"}},[_c('p',{staticClass:"selection-label mb-4 inter"},[_vm._v(" "+_vm._s(_vm.$t('singleReport.dateSelection.label'))+" "),_c('span',{staticClass:"font-weight-bold inter"},[_vm._v(_vm._s(_vm.$t('singleReport.dateSelection.button')))]),_vm._v(". ")]),_c('v-menu',{ref:"menu2",staticClass:"picker",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',{staticClass:"picker"},[_c('v-text-field',_vm._g(_vm._b({attrs:{"loading":_vm.isDisabled || _vm.isLoadingLatestData,"disabled":_vm.isDisabled || _vm.isLoadingLatestData,"label":"TT.MM.JJJJ","readonly":"","color":_vm.color,"background-color":"white","solo":"","dense":"","hide-details":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-icon',_vm._g({staticClass:"mdiCalendar",class:{
                  'active-calendar': attrs['aria-expanded'] === 'true',
                },attrs:{"disabled":_vm.isDisabled || _vm.isLoadingLatestData}},on),[_vm._v(_vm._s('mdi-calendar')+" ")])]},proxy:true}],null,true),model:{value:(_vm.dateMit),callback:function ($$v) {_vm.dateMit=$$v},expression:"dateMit"}},'v-text-field',attrs,false),on))],1)]}}],null,false,160915747),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{attrs:{"locale":"de-DE","color":_vm.color,"allowed-dates":_vm.allowedDatesMit,"min":_vm.min,"max":_vm.maxMit},on:{"change":_vm.save2},model:{value:(_vm.modelMit),callback:function ($$v) {_vm.modelMit=$$v},expression:"modelMit"}})],1)],1):_vm._e(),_c('v-col',{staticClass:"pl-0",attrs:{"xs":"12","sm":"12","md":"3","lg":"3","xl":"3"}},[_c('v-menu',{ref:"menu1",staticClass:"picker",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('div',{staticClass:"picker"},[_c('v-text-field',_vm._g(_vm._b({staticClass:"picker",attrs:{"loading":_vm.isDisabled || _vm.isLoadingLatestData,"disabled":_vm.isDisabled || _vm.isLoadingLatestData,"label":"TT.MM.JJJJ","readonly":"","background-color":"white","solo":"","dense":"","hide-details":"","color":_vm.color},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-icon',_vm._g({staticClass:"mdiCalendar",class:{
                  'active-calendar': attrs['aria-expanded'] === 'true',
                },attrs:{"disabled":_vm.isDisabled || _vm.isLoadingLatestData}},on),[_vm._v(_vm._s('mdi-calendar')+" ")])]},proxy:true}],null,true),model:{value:(_vm.dateVom),callback:function ($$v) {_vm.dateVom=$$v},expression:"dateVom"}},'v-text-field',attrs,false),on))],1)]}}]),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{attrs:{"locale":"de-DE","color":_vm.color,"allowed-dates":_vm.allowedDates,"min":_vm.min,"max":_vm.max},on:{"change":_vm.save1},model:{value:(_vm.modelVom),callback:function ($$v) {_vm.modelVom=$$v},expression:"modelVom"}})],1)],1),_c('v-col',{attrs:{"xs":"12","sm":"12","md":"3","xl":"3","lg":"3"}},[_c('v-btn',{staticClass:"btn",staticStyle:{"height":"38px"},attrs:{"color":_vm.color,"disabled":_vm.isLoadingLatestData || _vm.isDisabled,"loading":_vm.isLoadingLatestData || _vm.isDisabled},on:{"click":_vm.getLatestDataOfTheReport}},[_vm._v("Live-Daten laden")])],1),_c('v-spacer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }