































































































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { mapGetters } from 'vuex';

import { BASE_BLACK_COLOR, BASE_COLOR } from '@/utils/constants';

@Component({
  components: {},
  computed: {
    ...mapGetters({
      isDisabled: 'reports/getDisabled',
    }),
  },
})
export default class DateSelection extends Vue {
  @Prop() isShowMit!: Boolean;
  @Prop() labelVom!: String;
  @Prop() isLoadingLatestData: Boolean | undefined;
  @Prop() isShowTooltip!: Boolean;
  @Prop() availableData!: Array<object>;
  @Prop() dateVom!: string;
  @Prop() dateMit: string | undefined;
  @Prop() getLatestDataOfTheReport: Function | undefined;
  color = BASE_BLACK_COLOR;
  baseColor = BASE_COLOR;
  [x: string]: any;

  menu1: Boolean = false;
  menu2: Boolean = false;
  max: String = new Date().toISOString().substr(0, 10);
  dates: Array<string> = [];
  min = '';
  maxMit = '';
  get modelMit() {
    return this.dateMit && this.dateMit?.split('.').reverse().join('-');
  }
  set modelMit(val) {
    if (val) {
      this.$emit('changedDateMit', val);
    }
  }
  get modelVom() {
    return this.dateVom?.split('.').reverse().join('-');
  }
  set modelVom(val) {
    if (val) {
      this.maxMit = val;
      this.$emit('changedDateVom', val);
    }
  }
  save1(date) {
    if (!date) return;
    (this.$refs.menu1 as any).save(date);
  }
  save2(date) {
    if (!date) return;
    (this.$refs.menu2 as any).save(date);
  }
  allowedDates(val) {
    return this.dates.includes(val);
  }
  allowedDatesMit(val) {
    return this.dates.includes(val) && val !== this.modelVom;
  }

  mounted() {
    this.dates = this.availableData.map((item) => {
      return (item as any).date;
    });
    const arr = this.dates.map((item) => {
      return new Date(item).getTime();
    });
    const minDate = Math.min(...arr);

    this.min = this.$date(minDate).format('YYYY-MM-DD');
    this.maxMit = this.dateVom?.split('.').reverse().join('-');
  }
}
