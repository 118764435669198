



































































































































































































import Vue from 'vue';
import { Component, ModelSync, Prop } from 'vue-property-decorator';

import { BASE_BLACK_COLOR, BASE_COLOR, TAG_COLOR } from '@/utils/constants';

@Component({})
export default class NoteTagsHint extends Vue {
  @ModelSync('currentTags', 'changeCurrentTags')
  currentTagsValue!: String;
  @ModelSync('currentNote', 'changeCurrentNote')
  currentNoteValue!: String;
  @ModelSync('newNote', 'changeNewNote', { type: String })
  newNoteValue!: String;
  @ModelSync('newTags', 'changeNewTags', { type: Array })
  newTagsValue!: String;
  @Prop() menuItems!: Array<Object>;
  @Prop() isShowEditNote!: Boolean;
  @Prop() isEditNote!: Boolean;
  @Prop() isShowNewNote!: Boolean;
  @Prop() isCreatedNewNote!: Boolean;
  @Prop() isEditTags?: Boolean;
  @Prop() isShowNewTags?: Boolean;
  @Prop() isShowEditTags?: Boolean;
  @Prop() isCreatedNewTags?: Boolean;
  @Prop() openMenu!: Function;
  @Prop() closeMenu!: Function;
  @Prop() onEditNote!: Function;
  @Prop() onEditTags?: Function;
  @Prop() updateTags?: Function;
  @Prop() updateNote!: Function;
  @Prop() createNote!: Function;
  @Prop() createTags?: Function;
  @Prop() changeVisibilityNewNote!: Function;
  @Prop() changeVisibilityNewTags?: Function;

  predefinedTags = [
    'deutsche Anbieter',
    'Vermittler',
    'erweiterte Einlagensicherung',
    'flexible Angebote',
  ];
  color = BASE_BLACK_COLOR;
  tagColor = TAG_COLOR;
  baseColor = BASE_COLOR;
}
